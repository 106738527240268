@use 'styles/colors';

.textArea {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: fit-content;


    textarea {
        min-width: 100%;
        max-width: inherit;
    }

    .counter {
        display: flex;
        justify-content: end;
        color: colors.$base;

        .limit {
            color: colors.$error;
        }
    }
}
