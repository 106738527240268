@use 'styles/colors';
@use 'styles/borders';

.blockingCriteria {
    padding: 0 1rem;

    .noBlockingCriteriaText {
        padding-top: 1rem;
        line-height: 1.62rem;
    }

    .buttonContainer {
        padding: 1rem 0;

        .addButton {
            height: 2rem;
        }
    }
}
