@use 'styles/colors';
@use 'styles/borders';
@use 'styles/buttons';

.configurationSetup {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 75px); // 75px is height of NavBar. This prevents unnecessary scrollbar

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: colors.$base-white;
        padding: 1rem;
        @extend %thin-bottom;
        a {
            display: flex;
            align-items: center;
            gap: 0.25rem;
        }

        .buttons {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    main {
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
        row-gap: 16px;
        margin-top: 0.05rem;
        overflow: hidden;

        .contentBox {
            width: 100%;
            max-width: 1408px;
            height: 100%;
            max-height: 275px;
            background-color: colors.$base-white;
            padding: 2rem;
            border-radius: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            @extend %thin;
        }
    }
}
