@use 'styles/colors';

.tableNumericInput {
    display: flex;
    align-items: center;
    gap: 2rem;

    label {
        margin: 0;
        color: colors.$base-black;
        font-weight: 700;
        font-size: 0.75rem;
    }

    .inputWithError {
        display: flex;
        align-items: center;
        position: relative !important;

        input {
            width: 4.5rem;
            height: 1.6875rem;
            padding: 0.5rem;
            border-radius: 0;
            border: solid 0.0625rem colors.$base-darkest;
            &:disabled {
                background-color: colors.$base-lighter;
                border: solid 0.0625rem colors.$base-dark;
                color: colors.$base-dark;
            }
        }

        /* Hide spinner */
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            appearance: none !important;
            margin: 0;
        }

        &[type="number"] {
            -moz-appearance: textfield !important;
        }

        .errorBorder {
            border: solid 0.25rem colors.$secondary-dark;
        }

        .tooltip {
            background-color: unset;
            min-width: unset;
            min-height: unset;
            padding: 0;
            margin: 0;
            color: colors.$secondary;
            right: 100%;
            top: 50%;
            height: auto;
            width: auto;
            svg {
                margin: 0.25rem 0 0;
            }

            .tooltipIcon {
                margin-left: 0.25rem;
                font-size: 1rem;
                border-radius: 50%;
                width: 1.203125rem;
                height: 1.203125rem;
            }
        }
    }
    span {
        span {
            background-color: colors.$error-dark;
            &::after {
                left: 51%;
                border-top: 0.3125rem solid colors.$error-dark;
            }
        }
    }
}
