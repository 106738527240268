.exact-date-entry {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    .numeric-wrapper {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
    }

    .month,
    .day {
        flex: 1;
    }

    .year {
        flex: 1.5;
    }

    input {
        width: 100% !important;
    }
}
