@use 'styles/colors';

$small-font-size: 0.75rem;
$small-height: 1.375rem;
$small-textarea-height: 5.0625rem;

%small {
    --component-font-size: #{$small-font-size};
    --component-height: #{$small-height};

    font-size: var(--component-font-size);
}

%small-textarea {
    --component-height: #{$small-textarea-height};
}

//

$medium-font-size: 0.875rem;
$medium-height: 2rem;
$medium-textarea-height: 6rem;

%medium {
    --component-font-size: #{$medium-font-size};
    --component-height: #{$medium-height};

    font-size: var(--component-font-size);
}

%medium-textarea {
    --component-height: #{$medium-textarea-height};
}

//

$large-font-size: 1rem;
$large-height: 2.2rem;
$large-textarea-height: 10rem;

%large {
    --component-font-size: #{$large-font-size};
    --component-height: #{$large-height};

    font-size: var(--component-font-size);
}

%large-textarea {
    --component-height: #{$large-textarea-height};
}
