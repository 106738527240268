@use 'styles/colors';
@use 'styles/borders';

.dataElementsForm {
    height: 100%;
    width: 100%;
    max-width: 92.5rem;
    overflow: hidden;
    display: block;
    text-align: left;

    table {
        width: 100%;
        height: 37.1875rem;
        background-color: transparent;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0;
        font-size: 0.75rem;
        text-align: left;
        overflow: hidden;

        thead {
            position: sticky;
            top: 0;
            z-index: 20;
            text-align: left;
            align-items: center;
            background-color: colors.$base-white;
            height: 1.5625rem !important;
            min-height: 1.5625rem !important;
            max-height: 1.5625rem !important;
            padding: 0 !important;
            margin: 0 !important;
            box-sizing: border-box;

            tr th{
                max-height: 1.5625rem !important;
                line-height: 1.5625rem;
                padding-left: 0.375rem;
                padding-top: 0.1875rem;
                padding-bottom: 0.1875rem;
            }

            .border {
                th {
                    height: 0.0625rem;
                    width: 100%;
                    padding: 0;
                    background-color: colors.$base;
                    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
                }
            }

            .calculatedColumn {
                min-width: 6rem;
                padding-left: 0.3125rem;
                max-height: 1.5625rem;
            }

            .numericColumn {
                width: 8rem;
                padding-left: 0.3125rem;
                max-height: 1.5625rem;
            }
        }

        tr th,
        td {
            height: 1.5625rem;
            width: 13.5rem;
            padding: 0.2rem 0.4rem;
            text-align: left !important;
            vertical-align: middle;
        }

        .checkbox {
            width: 2.5rem;
            height: 1.25rem;
            padding-right: 2.5rem;
            padding-left: 1.125rem;
            align-items: center;
            justify-content: center;
            label::before {
                margin-top: -0.85rem;
                width: 1rem;
                height: 1rem;
            }
        }

        tbody tr:nth-child(odd) td{
            background-color: colors.$base-lightest;
            width: 100%;
            display: table-cell;
        }
    }

}

.headerWithIcon {
    display: inline-flex;
    align-items: center;
    gap: 0.1875rem;
    max-height: 1rem;
}


.infoIcon use{
    fill: colors.$primary !important;
    align-items: center;
    display: inline-flex;
    vertical-align: middle;
    max-height: 1rem !important;
    margin-left: 0.3125rem;
    transform: translateY(-0.125rem);
}

.infoIcon svg {
    height: 1.25rem !important;
    width: 1rem !important;
    vertical-align: middle;
    display: inline-block;
    gap:0.125rem;
}

.cardInfo {
    font-size: 0.875rem;
    color: colors.$base-darker;
    line-height: 1.6;
    margin-top: 0.5rem;
}