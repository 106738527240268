@use 'styles/colors';
@use 'styles/components';

.filter {
    border-color: colors.$base-darkest !important;

    font-size: var(--component-font-size);
    height: var(--component-height) !important;

    &.small {
        @extend %small;
    }

    &.medium {
        @extend %medium;
    }

    &.large {
        @extend %large;
    }
}
