@use 'styles/colors';

.actionMenu {
    position: relative;
    button {
        margin: 0;
    }
    .menuHeader {
        padding: 1rem;
        border-bottom: 1px solid colors.$base-lighter;
        h2 {
            margin: 0;
        }
        svg {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
        }
    }
    .menuContent {
        button {
            background-color: unset;
            border-top: 1px solid colors.$base-lighter;
            color: colors.$base-darkest;
            font-weight: 400;
            font-size: 1rem;
            text-align: left;
            width: 12.25rem;
            border-radius: 0;
            &:first-of-type {
                border-top: none;
            }
            &:hover {
                background-color: colors.$primary-lighter;
            }
            &:focus {
                outline: none;
            }
        }
    }
    .menuFooter {
        padding: 1rem;
        border-top: 1px solid colors.$base-lighter;
        display: flex;
        justify-content: center;
        button {
            background-color: colors.$primary;
        }
    }
    .actionMenuButton {
        padding: 0.25rem;
        line-height: 0;
        span:first-child {
            margin-left: 1rem;
        }
        span:last-child {
            margin-right: 1rem;
        }
        svg:first-child {
            margin-left: 0.5rem;
        }
        svg:last-child {
            margin-right: 0.5rem;
        }
    }
    .menu {
        position: absolute;
        top: 2.8rem;
        right: 0.05rem;
        display: flex;
        display: flex;
        flex-direction: column;
        z-index: 9999;
        border: 1px solid colors.$base-lighter;
        background-color: colors.$base-white;
        border-radius: 0.25rem;
        overflow: hidden;
        padding: 0.5rem 1rem;
    }
}
