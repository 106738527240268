@use 'styles/colors';

.header {
    &.fixed {
        position: sticky;
        left: 0;
        z-index: 30;

        min-width: 12.5rem;
        max-width: 12.5rem;
        border-right-width: 1px;
        border-right-style: solid !important;
        border-right-color: colors.$base;
    }

    &.sorted {
        background-color: colors.$cool-accent-light !important;
    }

    &.small {
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    &.medium {
        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }

    &.large {
        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        max-height: 2.5rem;

        transition-duration: 0.25s;
        transition-property: max-height;
        transition-timing-function: ease-in;

        &.extended {
            max-height: 5.125rem;
        }
    }

    .sortable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }

    svg {
        height: 1.125rem;
        width: 1.125rem;
    }
}
