@use 'styles/colors';
@use 'styles/borders';

.passForm {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .formContent {
        display: flex;
        flex-direction: column;
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 1rem 1rem 4rem 1rem;
        gap: 1rem;
    }

    .buttonBar {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        gap: 1rem;
        width: 100%;
        background-color: colors.$base-white;
        @extend %thin-top;

        & > div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
}
