@use 'styles/borders';
@use 'styles/components';

.horizontalInput {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.5rem;
    align-items: flex-start;
    gap: 2rem;

    @extend %thin-bottom;

    .left {
        --label-area-width: 13rem;
        --label-area-font-size: inherit;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-top: 0.5rem;
        min-width: var(--label-area-width);
        width: var(--label-area-width);

        label {
            font-weight: 700;
            font-size: var(--label-area-font-size);
        }
    }

    .right {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 0.5rem;

        .children {
            min-width: fit-content;
            width: 20rem;
            flex-shrink: 0;
        }

        .message {
            width: 9rem;
            flex-shrink: 1;
            flex-grow: 1;
        }
    }

    &.small {
        .left {
            --label-area-width: 12rem;
            --label-area-font-size: #{components.$small-font-size};

            padding-top: 0.25rem;
        }

        padding-left: 1rem;
    }

    &.medium {
        .left {
            --label-area-font-size: #{components.$medium-font-size};
        }
    }

    &.large {
        .left {
            --label-area-font-size: #{components.$large-font-size};
        }
    }
}
