@use 'colors.scss';

$button-padding-small: 0.5rem 0.75rem;
$button-padding-medium: 0.75rem 1.25rem;
$icon-small: 1rem;
$icon-medium: 1.25rem;
$icon-only-small: 1.5rem;
$icon-only-medium: 1.75rem;
$icon-padding-small: 0.25rem;
$icon-padding-medium: 0.5rem;

@mixin sizedButtonIcon($height: $icon-small, $width: $icon-small) {
    svg {
        height: $height;
        width: $width;
    }
}

.secondary {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: inset 0 0 0 2px colors.$primary !important;
    color: colors.$primary !important;

    &:hover {
        box-shadow: inset 0 0 0 2px colors.$primary-dark !important;
        color: colors.$primary-dark !important;
    }
    &:active {
        box-shadow: inset 0 0 0 2px colors.$primary-darker !important;
        color: colors.$primary-darker !important;
    }
}

.destructive {
    background-color: colors.$destructive !important;
    &:hover {
        background-color: colors.$secondary-dark !important;
    }
    &:active {
        background-color: colors.$secondary-darker !important;
    }
}

.destructive.secondary {
    background-color: colors.$base-white !important;
    color: colors.$error !important;
    box-shadow: inset 0 0 0 2px colors.$disabled !important;

    &:hover {
        color: colors.$secondary-dark !important;
    }
    &:active {
        color: colors.$error-darker !important;
    }
}

%button-small {
    padding: $button-padding-small;
    font-size: 0.875rem;
    height: auto;
    min-width: 2rem;

    @include sizedButtonIcon($icon-small, $icon-small);
}

%button-medium {
    padding: $button-padding-medium;
    font-size: 1rem;

    @include sizedButtonIcon($icon-medium, $icon-medium);
}

%button-large {
    padding: $button-padding-medium;
    font-size: 1rem;

    @include sizedButtonIcon($icon-medium, $icon-medium);
}

%icon-button {
    padding: $icon-padding-medium !important;
    @include sizedButtonIcon($icon-only-medium, $icon-only-medium);
}

%icon-button-small {
    padding: $icon-padding-small !important;
    @include sizedButtonIcon($icon-only-small, $icon-only-small);
}

.icon {
    &.small {
        @extend %icon-button-small;
    }
    &.medium {
        @extend %icon-button;
    }
}
