@mixin sizedDatePicker($backgroundSize: 1rem, $calendarWidth: 17.75rem) {
    & > div > button {
        background-size: $backgroundSize;
        background-position: left;
        width: $backgroundSize;

        &:focus {
            width: calc($backgroundSize + 0.5rem);
            background-position: center;
        }
    }

    & > div > input {
        margin-right: 0.25rem !important;
    }

    & > div > div {
        min-width: $calendarWidth !important;
    }
}

.small {
    @include sizedDatePicker(1rem);
}

.medium {
    @include sizedDatePicker(1.125rem);
}

.large {
    @include sizedDatePicker(1.25rem);
}
