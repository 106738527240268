@use 'styles/colors';
@use 'styles/borders';

.passList {
    display: flex;
    flex-direction: column;
    width: 18.75rem;
    padding: 1.5rem 1rem;
    align-items: flex-start;
    align-self: stretch;
    background-color: colors.$base-white;
    @extend %thin-right;
    overflow-y: auto;

    .heading {
        width: 100%;
        padding-bottom: 1rem;
        @extend %thin-bottom;
    }

    .addPassButton {
        margin-top: 0.5rem !important;
        text-decoration: none;
    }

    .noPassesText {
        padding: 0.75rem 0;
        color: colors.$base;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;
        @extend %thin-bottom;
    }
}
