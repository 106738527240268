.input {
    margin-top: 0 !important;
}
.grouped {
    align-items: center;
    display: flex;
    padding: 0;
    position: relative;

    appearance: none;

    input {
        padding-right: 1.5rem;
    }

    .suffix {
        padding: 0 0.5rem;
        position: absolute;
        right: 0;
        line-height: 0;
    }
}
