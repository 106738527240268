@use 'styles/colors';

.checkbox {
    width: 2.5rem;
    height: 1.25rem;
    padding-right: 2.5rem;
    padding-left: 1.125rem;
    align-items: center !important;
    justify-content: center;
    label::before {
        margin-top: -0.60rem;
        margin-left: 0.85rem;
        width: 1rem;
        height: 1rem;
    }
}

.field {
    font-weight: 400;
}