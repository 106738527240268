@use 'styles/colors';

.attributeEntry {
    display: flex;
    padding: 0.75rem 1rem 0.75rem 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    border-radius: 0.125rem;
    border: 2px solid colors.$base-darker;

    &.selected {
        border: 2px solid colors.$primary-light;
        background: colors.$primary-lighter;
    }

    .description {
        padding-left: 1.75rem;
        color: colors.$base-darkest;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;
    }
}
